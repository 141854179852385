import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../components/hero";
import { CaseList } from "../../components/caseList";

import doodle from "../../assets/images/services/projectmanagement.svg";

const ProjektmanagementSupport = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-projectmanagement.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="service.projectmanagement.link">
      <Seo
        title="Agiles Projektmanagement und Support – Monday Consulting"
        description="Als Projektmanager begleiten wir Sie partnerschaftlich während des gesamten Projektablaufs – und danach übernimmt unser Support, um Ihnen jederzeit mit unserer Expertise zur Seite zu stehen."
        keywords="Agile, Projektmanangement, ISO 27000-1, Scrum, Kanban, Customer Care, Support, Incident-Management"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Rundum versorgt – mit modernen Projektmethoden schnell und sicher ans Ziel"
        subline="Agiles Projektmanagement und langfristige Betreuung sind unsere Schlüssel für Ihren Erfolg."
        section="Projektmanagement & Support"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Projektmanagement & Support"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Single-Point-of-Contact für Ihr Projekt"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Single-Point-of-Contact für Ihr Projekt</h2>
            <p>
              Als Projektmanager sind wir Ihr erster Ansprechpartner für alle
              Fragen und Wünsche. Wir begleiten Sie partnerschaftlich während
              des gesamten Projektablaufs und stehen Ihnen jederzeit mit unserer
              Expertise zur Verfügung. Für Ihren langfristigen Erfolg verknüpfen
              unsere interdisziplinären Teams Strategie, Design und Technik – um
              die Lösung zu entwickeln, die Sie nachhaltig nach vorne bringt.
            </p>
            <p>
              Unsere Software-Entwicklung erfolgt üblicherweise nach agilen
              Methoden, zum Beispiel Scrum oder Kanban, die von unseren
              Agile-Developern gelebt und von zertifizierten Scrum- und
              Agile-Mastern begleitet und geleitet werden. Wesentlicher
              Bestandteil agiler Methoden ist eine kontinuierliche Anpassung und
              Verbesserung der gemeinsamen Prozesse. Auf diese Weise erreichen
              wir nicht nur das optimale Endergebnis, sondern sorgen für
              zukunftsfähige Abläufe und den langfristigen Erfolg Ihres
              Projekts.
            </p>
            <p>
              Auch nach Inbetriebnahme Ihrer Webseite sind wir weiterhin für Sie
              da. Unser Customer Care leistet operative Unterstützung,
              beantwortet Fragen rund um das System, hilft bei der Lösung von
              Problemen und unterstützt Sie bei der Weiterentwicklung und
              redaktionellen Pflege Ihrer Webseite.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Projektmanagement & Support im Detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-experienced bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Erfahren
            </h3>
            <p>
              Mit unserem Wissen aus unzähligen erfolgreichen Abschlüssen holen
              wir das Beste aus Ihrem Projekt heraus. Unsere langjährige
              Erfahrung ermöglicht es uns, Fallstricke und Schwierigkeiten
              frühzeitig zu erkennen und zu beheben. So sorgen wir für eine
              schnellere Umsetzung und optimale Ergebnisse, die Sie langfristig
              weiterbringen.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-knowhow bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Branchen Know-How
            </h3>
            <p>
              Die Zusammenarbeit mit Konzernen aus den unterschiedlichsten
              Branchen hat uns gelehrt, auf welche Themen es ankommt. So
              arbeiten wir eng mit Ihrer internen IT zusammen, um sensible Daten
              zu schützen, haben die Anforderungen eines Betriebsrats bzgl.
              Datenschutz im Auge und erarbeiten so die Lösung, die genau zu
              Ihnen passt.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-incidentmanagement bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Incident-Management
            </h3>
            <p>
              Bugs und Störungen können Sie uns jederzeit über unseren Helpdesk
              zukommen lassen. Unser Team nimmt sich der Sache an und löst
              Probleme in enger Abstimmung mit Ihnen.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-certified bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Zertifiziert
            </h3>
            <p>
              Monday Consulting ist vom TÜV Nord nach ISO 27000-1 zertifiziert.
              Die Informationssicherheitskonformität ist somit ein hohes Gut,
              welches wir auch in Ihrem Projekt gewährleisten werden.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Ausgewählte Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="haspa,lebensbaum" />
      </div>

      <ContactPerson
        headline="Dürfen wir Ihnen behilflich sein?"
        text="Überzeugen Sie sich noch heute von unseren Leistungen und nehmen Sie direkt Kontakt mit uns auf. Wir stehen Ihnen mit Rat und Tat zur Seite."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Geschäftsführer"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default ProjektmanagementSupport;
